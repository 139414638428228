import React from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .banner__main {
    background: #5db9db;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 48px;
    padding-bottom: 48px;
  }
  .banner__main > h3 {
    text-decoration: underline;
  }
  .text:hover {
    cursor: pointer;
  }
  .text {
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 49px;
    text-align: center;
    color: #ffffff;
    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 30px;
    }
  }
  .subtext {
    font-size: 32px;
    font-style: normal;
    font-weight: 800;
    line-height: 38px;
    text-align: center;
    color: #ffffff;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  .subtext a {
    color: white;
    text-decoration: none;
  }
`;

function Banner({ Cta }) {
  const handleClick = () => {
    ReactGA.event({
      category: "Homepage",
      action: "Access Your Copy Button Click",
    });
  };

  return (
    <StyledWrapper className="py-2">
      <div className="banner__main padding-left-8 padding-right-8">
        <h3 className="subtext mb-0">
          <a href={Cta.link} target="_blank" onClick={handleClick}>
            {Cta.text}
          </a>
        </h3>
      </div>
    </StyledWrapper>
  );
}

export default Banner;
