import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const StyledWrapper = styled.div`
  .submit-form {
    height: 50px;
    width: 180px;
    background-color: #464fd0;
    border: none;
    @media (max-width: 600px) {
      width: 120px;
    } 
  }

  .next-button {
    margin-right: 84px;
  }
  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }
  .cancel-btn:focus{
    border:none;
    box-shadow:none;
  }
  .career-heading {
    padding-left: 8%;
    padding-right: 8%;
    text-align: center;
    font-weight: 800;
  }
  .career-title {
    font-weight: bold;
    font-size: 18px;
  }
  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }
  .form-check-label {
    font-size: 16px;
    font-weight: normal;
  }
  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }

  .required-text {
    color: #e43d5a;
  }
`;

const RegisterSecondForm = ({
  setStep,
  err,
  setProgressValue,
  setCommonLayoutData,
  commonLayoutData,
  partialDataStore
}) => {
  const [universities, setUniversities] = useState([]);
  const [selectedUniversity, setSelectedUniversity] = useState(0);
  const [colleges, setColleges] = useState([]);
  const [campuses, setCampuses] = useState([]);
  const [courses, setCourses] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState();
  const [degrees, setDegrees] = useState();
  const enkiUrl = process.env.GATSBY_ENKI_URL;
  const [error, setError] = useState("");
  const [studentData, setStudentData] = useState("");
  const { register, handleSubmit, errors, getValues } = useForm();
  const [currentYearCalculation, setCurrentYearCalculation] = useState(null);

  useEffect(() => {
    document.getElementsByClassName('apply-now')[0].scrollTop = 0;
    getDegrees();
    fetchUniversities();
    fetchUniversityCourses();
    async function fetchUniversityData() {
      if (
        selectedUniversity === 30 ||
        selectedUniversity === 46 ||
        selectedUniversity === 57 ||
        selectedUniversity === 111
      ) {
        const response = await fetch(
          `${enkiUrl}/universities/get-info/${selectedUniversity}`
        );
        const responseJson = await response.json();
        if (selectedUniversity === 30 || selectedUniversity === 57) {
          setColleges(responseJson.university.college);
        } else if (selectedUniversity === 46 || selectedUniversity === 111) {
          setCampuses(responseJson.university.campus);
        }else{
          delete commonLayoutData.campus_id
          delete commonLayoutData.college_id
        }
      }
    }
    fetchUniversityData();
  }, [selectedUniversity]);

  const fetchUniversityCourses = async () => {
    const response = await fetch(`${enkiUrl}/course/get-university-courses`);
    const responseJson = await response.json();
    let data = responseJson.universityCourses;
    //while save for later seting the checkbox value of student type
    // if(commonLayoutData.token){
    //   setStudentData(commonLayoutData?.student_type);
    // }
    setCourses(data);
  };

  const getDegrees = async () => {
    var response = await fetch(`${enkiUrl}/degree/get-degrees`);
    const responseJson = await response.json();
    let data = responseJson.degrees;
    setDegrees(data);
  };

  async function fetchUniversities() {
    const response = await fetch(
      `${enkiUrl}/universities/get-active-universities`
    );
    const responseJson = await response.json();
    let universities = responseJson.universities;
    universities.sort((a, b) => a.name > b.name);
    setUniversities(universities);
  }

  useEffect(() => {
    setStudentData(commonLayoutData?.student_type)
    if(commonLayoutData?.university_id) commonLayoutData.university_id = Number(commonLayoutData.university_id)
    if(commonLayoutData?.university_course_id) commonLayoutData.university_course_id = Number(commonLayoutData.university_course_id)
    if(commonLayoutData?.year_of_study) commonLayoutData.year_of_study = Number(commonLayoutData.year_of_study)
    if(commonLayoutData?.course_duration) commonLayoutData.course_duration = Number(commonLayoutData.course_duration)
    if(commonLayoutData?.degree_id) commonLayoutData.degree_id = Number(commonLayoutData.degree_id)
    setSelectedCourse(Number(commonLayoutData?.university_course_id))
  }, [commonLayoutData]);

  useEffect(() => {
    currentYearCal();
  }, []);
 
  // Function to calculate the academic year
  const currentYearCal = () => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1; // Getting the current month (1-based: January = 1, December = 12)
    // Determine the academic year based on whether it's September or later
    const yearToDisplay = currentMonth >= 9 ? today.getFullYear() : today.getFullYear() - 1;
    setCurrentYearCalculation(yearToDisplay);
  };
 
  const saveForLater = (pageId, progressPercent) => {
    let data = { ...data, ...commonLayoutData };
    //save for later move to last updated page
    if (commonLayoutData.page_id > pageId) {
      data.page_id = commonLayoutData.page_id;
      data.progress_percentage = commonLayoutData.progress_percentage;
    }
    else {
      data.page_id = pageId;
      data.progress_percentage = progressPercent;
    }
    if(selectedCourse != 96){
      delete data.university_course_other_text
    }
    partialDataStore({ data });
  }

  const submitData = data => {
    if (
      data["year_of_study"] === "0" ||
      data["course_duration"] === "0" ||
      data["university_id"] === "Select University" ||
      data["expected_start_date"] === "Select Opening Year" ||
      data["university_course_id"] === "Select Course" ||
      data["study_type"] == ""
    ) {
      if (data["year_of_study"] === "") {
        setError("Please select year of study");
      }
      if (data["university_id"] === "Select University") {
        setError("Please select university");
      }
      if (data["course_duration"] === "") {
        setError("Please select course length");
      }
      if (data["expected_start_date"] === "Select Opening Year") {
        setError("Please select expected start date");
      }
      if (data["university_course_id"] === "Select Course") {
        setError("Please select course");
      }
      if (data["study_type"] == "") {
        setError("Please select studying type");
      }
      return;
    }
    if (data["year_of_study"] > data["course_duration"]) {
      setError("Year of study is longer than Course Duration");
      return;
    }
    if(selectedCourse != 96){
      delete commonLayoutData.university_course_other_text
    }
    setCommonLayoutData({ ...commonLayoutData, ...data });
    setStep(3);
    setProgressValue("40");
  };

  return (
    <StyledWrapper className='apply-now'>
      <div>
        {/* <h1 className="mb-2 mb-md-4">APPLY TO UPREACH</h1> */}
        <h2 className="mb-2 mb-md-4">Personal Details</h2>
        <h4 className="mb-4">UNIVERSITY INFORMATION</h4>
        <Form onSubmit={handleSubmit(submitData)}>
          <Form.Label className="mt-0">
            {" "}
            University<span className="required-text"> *</span>{" "}
          </Form.Label>
          <Form.Control
            ref={register({ required: true })}
            name={"university_id"}
            as="select"
            onChange={e => {
              setSelectedUniversity(Number(e.target.value))
              setCommonLayoutData({ ...commonLayoutData, ...getValues() })
            }}
          ><option value="" selected>Select university</option>
            {universities &&
              universities.length > 0 &&
              universities.map(university => (
                <option
                  value={university.id}
                  selected={
                    university.id === Number(commonLayoutData?.university_id)
                  }
                >
                  {university.name}
                </option>
              ))}
          </Form.Control>
          {errors.university_id && (
            <p className="mb-0 text-danger font-weight-bold">
              Please select your university
            </p>
          )}

          {(selectedUniversity === 30 || selectedUniversity === 57) && (
            <div>
              <Form.Label>
                {" "}
                College<span className="required-text"> *</span>{" "}
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                name="college_id"
                as="select"
                onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
                defaultValue={
                  commonLayoutData !== null ? commonLayoutData.college_id : ""
                }
              >
                {colleges &&
                  colleges.length > 0 &&
                  colleges.map(college => (
                    <option
                      value={college.id}
                      selected={
                        college.id === Number(commonLayoutData?.college_id)
                      }
                    >
                      {college.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.college_id && (
                <p className="mb-0 text-danger font-weight-bold">
                  Please select your college
                </p>
              )}
            </div>
          )}
          {(selectedUniversity === 46 || selectedUniversity === 111) && (
            <div>
              <Form.Label>
                {" "}
                Campus<span className="required-text"> *</span>{" "}
              </Form.Label>
              <Form.Control
                ref={register({ required: true })}
                name="campus_id"
                as="select"
                onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
                defaultValue={
                  commonLayoutData !== null ? commonLayoutData.campus_id : ""
                }
              >
                {campuses &&
                  campuses.length > 0 &&
                  campuses.map(campus => (
                    <option
                      value={campus.id}
                      selected={
                        campus.id === Number(commonLayoutData?.campus_id)
                      }
                    >
                      {campus.name}
                    </option>
                  ))}
              </Form.Control>
              {errors.campus_id && (
                <p className="mb-0 text-danger font-weight-bold">
                  Please select your campus
                </p>
              )}
            </div>
          )}

          <Form.Label>
            {" "}
            University Course <span className="required-text"> *</span>{" "}
          </Form.Label>
          <Form.Control
            ref={register({ required: true })}
            name="university_course_id"
            as="select"
            onChange={e => {
              setSelectedCourse(Number(e.target.value))
              setCommonLayoutData({ ...commonLayoutData, ...getValues() })
            }}
            defaultValue={
              commonLayoutData !== null
                ? commonLayoutData.university_course_id
                : ""
            }
          ><option value="" selected>Select course</option>
            {courses &&
              courses.length > 0 &&
              courses.map(course => (
                <option
                  value={course.id}
                  selected={
                    course.id === Number(commonLayoutData?.university_course_id)
                  }
                >
                  {course.name}
                </option>
              ))}
          </Form.Control>
          {errors.university_course_id && (
            <p className="mb-0 text-danger font-weight-bold">
              Please select your course
            </p>
          )}
          {selectedCourse === 96 && (
            <div>
              <Form.Label>
                {" "}
                Please enter your course{" "}
                <span className="required-text"> *</span>{" "}
              </Form.Label>
              <Form.Control
                defaultValue={
                  commonLayoutData !== null
                    ? commonLayoutData.university_course_other_text
                    : ""
                }
                type="text"
                ref={register({ required: true })}
                onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
                name="university_course_other_text"
              />
              {errors.university_course_other_text && (
                <p className="mb-0 text-danger font-weight-bold">
                  Please enter your course
                </p>
              )}
            </div>
          )}
          <Form.Label>
            Degree Type <span className="required-text"> *</span>
          </Form.Label>
          <Form.Control
            as="select"
            ref={register({ required: true })}
            name="degree_id"
            onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
          >
            <option value="" selected disabled>
              Select degree type
            </option>
            {degrees &&
              degrees.length > 0 &&
              degrees.map(degree => (
                <option
                  value={degree.id}
                  selected={degree.id === Number(commonLayoutData?.degree_id)}
                >
                  {degree.name}
                </option>
              ))}
          </Form.Control>
          {errors.degree_id && (
            <p className="mb-0 text-danger font-weight-bold">
              Please select your degree
            </p>
          )}

          <Form.Label>
            {" "}
            Year of study as of September {currentYearCalculation}{" "}
            <span className="required-text"> *</span>{" "}
          </Form.Label>
          <Form.Control
            value={commonLayoutData?.year_of_study}
            as="select"
            ref={register({ required: true })}
            name="year_of_study"
            onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
          >
            <option value="" selected disabled>
              Select year of study
            </option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </Form.Control>
          {errors.year_of_study && (
            <p className="mb-0 text-danger font-weight-bold">
              Please select your year of study
            </p>
          )}

          <Form.Label>
            {" "}
            Length of undergraduate course (years){" "}
            <span className="required-text"> *</span>{" "}
          </Form.Label>
          <Form.Control
            value={commonLayoutData?.course_duration}
            as="select"
            ref={register({ required: true })}
            name="course_duration"
            onChange={e => setCommonLayoutData({ ...commonLayoutData, ...getValues() })}
          >
            <option value="" selected disabled>
              Select length of course
            </option>
            <option value={1}>1</option>
            <option value={2}>2</option>
            <option value={3}>3</option>
            <option value={4}>4</option>
            <option value={5}>5</option>
            <option value={6}>6</option>
          </Form.Control>
          {errors.course_duration && (
            <p className="mb-0 text-danger font-weight-bold">
              Please select your length of undergraduate
            </p>
          )}

          <Form.Label>
            {" "}
            Are you studying full time or part time?{" "}
            <span className="required-text"> *</span>{" "}
          </Form.Label>
          <Form.Group>
            <Form.Check
              ref={register({ required: true })} value="FULL_TIME" id="fullTime" htmlFor="fullTime"
              onClick={e => {
                setStudentData("FULL_TIME")
                setCommonLayoutData({ ...commonLayoutData, ...getValues() })
              }}
              type="radio" label="I am studying full time" name="student_type"
              checked={studentData == "FULL_TIME" ? true : false}
            />
            <Form.Check
              ref={register({ required: true })} value="PART_TIME" id="partTime" type="radio"
              onClick={e => {
                setStudentData("PART_TIME")
                setCommonLayoutData({ ...commonLayoutData, ...getValues() })
              }}
              name="student_type" htmlFor="partTime" label="I am studying part time"
              checked={studentData == "PART_TIME" ? true : false}
            />
            {errors.student_type && (
              <p className="mb-0 text-danger font-weight-bold">
                Please select study type
              </p>
            )}
          </Form.Group>

          {error !== "" && <div className="fail-text">{error}</div>}

          {err !== "" && <div className="fail-text">{err}</div>}

          <div className="row text-center">
            <div className="col-lg-2 text-lg-left order-lg-first order-last">
              <Button onClick={() => { setStep(1); setProgressValue(0) }} className="submit-form mt-3 p-2" type="button">
                BACK
              </Button>
            </div>
            <div className="col-lg-10 text-lg-right">
              <button onClick={(e) => {
                e.preventDefault();
                saveForLater(2, 20);
              }} className="mr-lg-5 mr-md-4 cancel-btn btn">
                SAVE FOR LATER
              </button>
              <Button className="submit-form mt-3 p-2" type="submit">
                NEXT
              </Button>
            </div>
          </div>
        </Form>
      </div>
    </StyledWrapper>
  );
};

export default RegisterSecondForm;