import React, { useState } from 'react'
import './Form.css'
import { useForm } from "react-hook-form";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styled from 'styled-components'
import { Button, Form } from "react-bootstrap"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactGA from "react-ga";

const StyledWrapper=styled.div`
.head-to-head {
    color: black;
    text-decoration: underline;
    
  }

  .submit-form {
    background-color: #5DB9DB;
    font-size: 21px;
    font-weight:bold;
    width:100%;
    border:0px;
  }

  textarea{
    border: 1px solid #ced4da;
    border-radius: .25em;
    outline:none;
    width: 100%;
    &:focus{
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }

  .select-form{
    width:100%;
    border: 1px solid #ced4da;
    border-radius: .25em;
    outline:none;
    width: 100%;
    &:focus{
      border-color: #80bdff;
      box-shadow: 0 0 0 0.2rem rgb(0 123 255 / 25%);
    }
  }

  .toast-color{
    background:#5DB9DB;
  }

  .progressClass{
      background: white;
  }

  .toastBodyClass{
      color: black;
  }
`

function ContactForm() {
    const { register, handleSubmit, watch, errors, setValue } = useForm({mode: "onChange"})
    const [error,setError] = useState('');
    const [profession,setProfession] = useState('');
    const [heard, setHeard] = useState('');
    const [submitted,setSubmitted] = useState(false)
    const [disableBTN,setDisableBTN] = useState(false)

    const notifyToast = () =>{
      return toast("Your details have been submitted",{
          className: 'toast-color',
          progressClassName: 'progressClass',
          bodyClassName: 'toastBodyClass'
      });
    }
    const onSubmit = data => {
        fetch('https://formspree.io/f/xrgonrgn',{
            method:'POST',
            headers: {
                "Content-Type": "application/json",
              },
            body: JSON.stringify(data),
        }).then(function(response){
            if(response.status == 200){
              setDisableBTN(true)
              notifyToast();
            } else {
              return response.json().then(data=>setError(data.message));
            }
        }).catch(err => console.log(err));
        ReactGA.event({
          category:"General Enquiries Form",
          action: "Submit",
        });
    }

    return (
    <StyledWrapper className="px-0 col-md-8 col-12">
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Label className="form__label my-3">First Name<span className="astrix-color">*</span></Form.Label>
            <Form.Control type="text" ref={register({ required: true})} className="py-2" name="first_name" placeholder="First name"/>
            <Form.Label className="form__label my-3">Surname<span className="astrix-color">*</span></Form.Label>
            <Form.Control type="text" ref={register({ required: true})} className="py-2" name="surname"  placeholder="Surname"/>
            <Form.Label className="form__label my-3">E-mail address<span className="astrix-color">*</span></Form.Label>
            <Form.Control type="email" ref={register({ required: true})} className="py-2" name="email"   placeholder="you@domain.com"/>
            <Form.Label  className="form__label my-3">Message<span className="astrix-color">*</span></Form.Label>
            <textarea type="text" ref={register({ required: true})} placeholder="Max. 500 characters" className="textarea p-2" name="message" rows="4"></textarea>
            <Form.Label className="form__label my-3">I am a<span className="astrix-color">*</span></Form.Label>
            <select className="select-form p-2" name="profession" ref={register} onChange={e => setProfession(e.target.value)}>
              <option value=""></option>
              <option value="Parent/Guardian">Parent/Guardian</option>
              <option value="Student">Student</option>
              <option value="Teacher">Teacher</option>
              {/* <option value="Education sector stakeholder">Education sector stakeholder</option> */}
              <option value="Other">Other (Please provide more detail in the box below)</option>
            </select>
            {profession ==="Other" && (<Form.Label className="form__label my-3">If other, please give details:</Form.Label>)}
            {profession ==="Other" && (<Form.Control type="text" ref={register({ required: profession === "Other"})} className="py-2" name="profession_other" placeholder="" />)}
            <Form.Label className="form__label my-3">Where did you hear about us?<span className="astrix-color">*</span></Form.Label>
            <select name="hear_about_us" className="select-form p-2" ref={register} onChange={e => setHeard(e.target.value)}>
              <option value=""></option>
              <option value="The Careers & Enterprise Company">The Careers & Enterprise Company</option>
              <option value="Letter from Aspire/upReach">Letter from Aspire/upReach</option>
              <option value="News outlets">News outlets</option>
              <option value="A parent">A parent</option>
              <option value="A student">A student</option>
              <option value="A teacher">A teacher</option>
              <option value="TES">TES</option>
              <option value="upReach">upReach website</option>
              {/* <option value="Social media">Social media</option>
              <option value="A friend or colleague">A friend or colleague</option>
              <option value="At school">At school</option> */}
              <option value="Other">Other (Please provide more detail in the box below)</option>
            </select>
            {heard === "Other" && (<Form.Label className="form__label my-3">If other, please give details:</Form.Label>)}
            {heard === "Other" && (<Form.Control type="text" ref={register({ required: heard === "Other"})} className="py-2" name="hear_about_us_other" placeholder="" />)}
            <div className="form-check mt-3">
                <input className="form-check-input" type="checkbox" name="privacy_consent" ref={register} required/>
                <label className="form-check-label" >
                I have read and understood the  <AnchorLink target="_blank" className="head-to-head"  to="/privacy-policy">Privacy Policy</AnchorLink> and consent to receiving emails from upReach. We will not pass on your details to any third parties.
                </label>
            </div>
            {error !== '' && <div>Form not submitted. {error}</div>}
            <Button className="submit-form mt-5 p-2" type="submit" disabled={disableBTN}>
                {disableBTN == false ? "SUBMIT" : "SUBMITTED"}
            </Button>
            <ToastContainer autoClose={false}/>
        </Form>
    </StyledWrapper>
    )
}

export default ContactForm
