import React, { Component } from "react";
import Slider from "react-slick";
import styled from "styled-components";
import header1 from "../images/HeaderImages/header1.png";
import header2 from "../images/HeaderImages/header2.png";
import header3 from "../images/HeaderImages/header3.png";
import header4 from "../images/HeaderImages/header4.png";
import header5 from "../images/HeaderImages/header5.png";
import header6 from "../images/HeaderImages/header6.png";

const StyledWrapper = styled.div`
  .Mobile__image {
    width: 100%;
    height: 200px;
    object-fit: cover;
  }
  .Main__text {
    padding-top: 20px;
    padding-bottom: 20px;
    background: #5db9db;
    font-size: 20px;
    font-weight: bold;
  }
  .text {
    color: white;
    text-align: center;
  }
`;

function MobileAnimate({ data, text }) {
  let settings = {
    autoplay: true,
    infinite: true,
    speed: 1000,
    autoplaySpeed: 2000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const MainUrl = process.env.GATSBY_API_URL;

  return (
    <StyledWrapper>
      <div className="container-fluid padding-right-8 padding-left-8 d-block d-md-none">
        <Slider {...settings}>
          {data.length > 0 &&
            data.map(
              i =>
                i.Image && (
                  <div>
                    <img
                      className="Mobile__image img-fluid"
                      src={MainUrl + i.Image.url}
                      alt=""
                    />
                  </div>
                )
            )}
        </Slider>
      </div>
      <div className="Main__text padding-right-8 padding-left-8 d-flex justify-content-center align-items-center d-block d-md-none">
        <p className="text">{text}</p>
      </div>
    </StyledWrapper>
  );
}
export default MobileAnimate;
