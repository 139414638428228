import React, { useState, useEffect } from "react";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import RegisterModal from "./RegisterModal";
import { ToastContainer, toast } from "react-toastify";


const StyledWrapper = styled.div`
  .home-summary-cta {
    text-decoration: none;
    color: black;
  }

  .mission {
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .summary-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    font-family: ${props => props.theme["primaryFont"]} !important;
    text-transform: uppercase;
  }

  h1 {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  p a {
    text-decoration: underline;
    color: black;
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 1rem !important;
    font-weight: 800;
  }

  p strong a {
    color: black !important;
    text-decoration: underline !important;
    font-family: Nunito Sans !important;
    font-size: 23px !important;
    font-weight: 800 !important;
    line-height: 43px !important;
    text-transform: none !important;
    @media (max-width: 600px) {
      font-size: 20px !important;
      line-height: 18px !important;
    }
  }

  .register-btn {
    text-decoration: underline;
    cursor: pointer;
    font-size: 23px;
  }

  .toast-color {
    background: #5db9db;
  }

  .progressClass {
    background: white;
  }

  .toastBodyClass {
    color: black;
  }
  .content iframe {
    @media (min-width: 300px) and (max-width: 896px) {
      width: 324px;
      height: 180px;
    }
    @media (min-width: 200px) and (max-width: 280px) {
      width: 237px;
      height: 134px;
    }
    @media (min-width: 768px) and (max-width: 1180px) {
      width: 324px;
      height: 180px;
    }
  }
`;

const Register = ({ summary }) => {
  const [modalOpen, setModalOpen] = React.useState();
  const [urlToken, setUrlToken] = React.useState();

  const leftlines = summary.leftSideSection.split('\n')
  const rightlines = summary.rightSideSection.split('\n')

  useEffect(() => {
    if(window.location.search.includes('?')){
      let token = window.location.search.replace("?", '');
      if(token && window.location.search.includes('?UPR_')){
        setModalOpen(true);
        setUrlToken(token);
      }
    }
  }, [])

  const notifyToastReg = () => {
    return toast(
      "Thank you for registering for support from upReach. We will be in contact shortly.",
      {
        className: "toast-color",
        progressClassName: "progressClass",
        bodyClassName: "toastBodyClass",
        autoClose: 5000,
      }
    );
  };

  const notifyFn1 = () =>
    toast(
      "Thank you for saving your data.",
      {
        className: "toast-color",
        progressClassName: "progressClass",
        bodyClassName: "toastBodyClass",
        autoClose: 5000,
      }
    );

  useEffect(() => {
    if (window.location.href.includes("isRegister=true")) {
      setModalOpen(true);
    }
    if (modalOpen == false) {
      window.history.replaceState({}, document.title, "/");
      setModalOpen(false);
    }
  }, [modalOpen]);

  return (
    <StyledWrapper>
      <div className="content">
      <div className="container-fluid py-5 padding-left-8 padding-right-8">
        <h1>REGISTER</h1>
        <div className="row">
          <div className="col-md-6 col-sm-12">
          
          <div>
                {leftlines && leftlines.map((line, index) => {
                  const NewLeftLine = line.replace(/```/g, '');
                  return(
                  <div>
                  {line && <ReactMarkdown className="markdown" source={NewLeftLine} escapeHtml={false} />}
                  </div>
                  )
                })
            }
            </div>
            {/* {summary.leftSideSection && (
              <ReactMarkdown
                className="markdown"
                source={summary.leftSideSection}
              />
            )} */}
          </div>
          <div className="col-md-6 col-sm-12">
            {/* {summary.rightSideSection && (
              <ReactMarkdown
                className="markdown"
                source={summary.rightSideSection}
              />
            )} */}
            <div>
                {rightlines && rightlines.map((line, index) => {
                  const NewRightLine = line.replace(/```/g, '');
                  return(
                  <div>
                  {line && <ReactMarkdown className="markdown" source={NewRightLine} escapeHtml={false} />}
                  </div>
                  )
                })
            }
            </div>
          </div>
        </div>
        <h2 className="register-btn" onClick={() => setModalOpen(true)}>
          Register Now
        </h2>
        {modalOpen && (
          <RegisterModal
            setModalOpen={setModalOpen}
            modalOpen={modalOpen}
            notifyToastReg={notifyToastReg}
            notifyFn1={notifyFn1}
            token={urlToken}
          />
        )}
      </div>
      {/* <ToastContainer /> */}
      </div>
    </StyledWrapper>
  );
};

export default Register;
