import React from "react";
import Fade from "react-reveal/Fade";
import styled from "styled-components";
import header1 from "../images/HeaderImages/header1.png";
import header2 from "../images/HeaderImages/header2.png";
import header3 from "../images/HeaderImages/header3.png";
import header4 from "../images/HeaderImages/header4.png";
import header5 from "../images/HeaderImages/header5.png";
import header6 from "../images/HeaderImages/header6.png";

const StyledWrapper = styled.div`
  .Animate__text {
    color: #fff;
    font-family: Nunito Sans;
    font-size: 36px;
    font-style: normal;
    font-weight: 800;
    line-height: 49px;
    letter-spacing: 0px;
    text-align: center;
  }

  .Animate_image {
    height: 300px;
    object-fit: cover;
    object-position: left;
    @media (max-width: 1200px) {
      height: 250px;
    }
    @media (max-width: 1000px) {
      height: 200px;
    }
  }

  .Animate__main {
    background: #5db9db;
    padding: 2% 8%;
  }
`;

function HeroAnimate({ Image2, data, text }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="Animate__main container-fluid d-md-block d-none ">
        <div className="row">
          {data.length > 0 &&
            data.map(i => (
              <Fade delay={i.delay}>
                <div className="col-md-4 col-xl-4">
                  {i.Image && (
                    <img
                      className="Animate_image img-fluid"
                      src={mainUrl + i.Image.url}
                      alt=""
                    />
                  )}
                </div>
              </Fade>
            ))}
        </div>

        <div className="padding-right-8 padding-left-8">
          <Fade left delay={300}>
            <p className="Animate__text pt-5">{text}</p>
          </Fade>
        </div>

        {/* <div className="row justify-content-around">
          <div className="d-flex">
            <div className="col-md-4 col-12">
              <Fade delay={1300}>
                <img className="Animate_image img-fluid" src={header4} />
              </Fade>
            </div>

            <div className="col-md-4 col-12">
              <Fade delay={2300}>
                <img className="Animate_image img-fluid" src={header5} />
              </Fade>
            </div>

            <div className="col-md-4 col-12">
              <Fade delay={3300}>
                <img className="Animate_image img-fluid" src={header6} />
              </Fade>
            </div>
          </div>
        </div> */}
      </div>
    </StyledWrapper>
  );
}

export default HeroAnimate;
