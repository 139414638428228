import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import styled from "styled-components";
import { useForm } from "react-hook-form";

const StyledWrapper = styled.div`
  .submit-form {
    height: 50px;
    width: 180px;
    background-color: #464fd0;
    border: none;
    @media (max-width: 600px) {
        width: 120px;
      }   
  }
  .next-button {
    margin-right: 84px;
  }
  .cancel-btn {
    font-size: 16px;
    font-weight: bold;
    color: #464fd0;
    cursor: pointer;
    position: relative;
    top: 8px;
  }
  .cancel-btn:focus{
    border:none;
    box-shadow:none;
  }
  .career-heading {
    padding-left: 8%;
    padding-right: 8%;
    text-align: center;
    font-weight: 800;
  }
  .career-title {
    font-weight: bold;
    font-size: 18px;
  }
  .checkbox-link {
    display: inline-block;
    color: black;
    text-decoration: underline;
  }
  .form-check-label {
    font-size: 16px;
    font-weight: normal;
  }
  .fail-text {
    padding-top: 10px;
    padding-right: 80px;
    font-weight: bolder;
    color: #e43d5a;
  }
  .required-text {
    color: #e43d5a;
  }
  .bg_load {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
	height: 100%;
	background: #000;
	opacity:0.2;
	z-index:9999;
    display: flex;
	}
`;
const RegisterFifthForm = ({
    setStep,
    setProgressValue,
    err,
    submitForm,
    setCommonLayoutData,
    commonLayoutData,
    partialDataStore
}) => {
    const { register, handleSubmit, getValues, errors } = useForm({ mode: "onChange" });
    const [ethnicities, setEthnicities] = useState([]);
    const [ethnicityId, setEthnicityId] = useState("");
    const [ethnicName, setEthnicName] = useState("");
    const [ethnicity, setEthnicity] = useState([]);
    const [otherEthnicName, setOtherEthnicName] = useState("");
    const [otherEnthnicityFlag, setOtherEnthnicityFlag] = useState(null);
    const [african, setAfrican] = useState(null);
    const [otherField, setOtherField] = useState(null);
    const [otherEthinicFullName, setOtherEthinicFullName] = useState("");
    const [selectedCategory, setSelectedCategory] = useState([]);
    const [disadvantages, setDisadvantages] = useState([]);
    const [otherDisadvantageId, setOtherDisadvantageId] = useState("");
    const [ethnicitiesList, setEthnicitiesList] = useState([]);
    const [disabilityYes, setDisability] = useState(false);
    const [occupationSuggestions, setOccupationSuggestions] = useState(null);
    const [other_occupation_flag, setOtherOccupationFlag] = useState(false);
    const [occupationData, setOccupationData] = useState(false);
    const [occupation, setOccupation] = useState("");
    const [careerOccupation, setCareerOccupation] = useState("");
    const [dataPolicy, setDataPolicy] = useState(false);
    const [noOccupation, setNoParentOccupation] = useState(false);
    const [references, setReferences] = useState([]);
    const [finalData, setFinalData] = useState(getValues());
    const enkiUrl = process.env.GATSBY_ENKI_URL;
    const [error, setError] = useState("");

    useEffect(() => {
        document.getElementsByClassName('apply-now')[0].scrollTop = 0;
        fetchReferences();
        fetchReferences();
        getEthnicity();
        getDisadvantages();
        checkOtherEthnicity();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            if (commonLayoutData.ethnicity_category !== "") {
                setEthnicityId(commonLayoutData?.ethnicity_category)
            }
            setOtherOccupationFlag(commonLayoutData && commonLayoutData.parent_carer_one_text == "Other" && true);
        }, 1000);

        // setting disability value
        if (commonLayoutData.disability) {
            if (commonLayoutData?.disability === "YES") {
                setDisability(true);
            } else {
                setDisability(false);
            }
        }

        setCareerOccupation(commonLayoutData?.parent_carer_one_occupation_group)
        setNoParentOccupation(commonLayoutData ? commonLayoutData.no_occupation : false);
        setDataPolicy(commonLayoutData ? commonLayoutData.form1_data_policy : false)

    }, [commonLayoutData]);

    useEffect(() => {
        getEthnicity();
    }, [commonLayoutData?.ethnicity_category]);

    useEffect(() => {
        if(commonLayoutData?.no_occupation === true){
            let occupation_group = document.querySelector(`#${commonLayoutData?.parent_carer_one_occupation_group}`)
            occupation_group && occupation_group.setAttribute("checked",false)
            commonLayoutData.parent_carer_one_text = null
            commonLayoutData.parent_carer_one_occupation_group = null
            commonLayoutData.other_occupation_text = null
            delete errors.parent_carer_one_occupation_group
            delete errors.parent_carer_one_text
            setCareerOccupation("")
        }
    }, [commonLayoutData?.no_occupation]);

    const handleChange = e => {
        const value = e.target.value;
        if (value === "YES") {
            setDisability(true);
        } else {
            setDisability(false);
        }
        setCommonLayoutData({ ...commonLayoutData, ...getValues() })
    };

    const handleChange1 = e => {
        let id = e.target.id;
        let name = e.target.name;
        let value = e.target.value;
        if (id == "ethnicity_category") {
            setEthnicityId(value);
            var ethincity_data = {};
            ethnicities.forEach((item, index) => {
                if (item.cat_id == value) {
                    ethincity_data = item;
                }
            });
            showEthnicities(ethincity_data);
        }
        if (id == "ethnicity") {
            checkOtherEthnicity(value);
        }
        if (name == "no_occupation") {
            setOccupationData(e.target.checked);
        }
        setCommonLayoutData({ ...commonLayoutData, ...getValues() })
    };

    /* Getting the getDisadvantages values and storing it in state variable */
    const getDisadvantages = () => {
        var url = `${enkiUrl}/disadvantage/get-disadvantage`;
        const requestOptions = {
            method: "GET",
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status == "SUCCESS") {
                    setDisadvantages(data.disadvantages);
                    setOtherDisadvantageId(data.other_id);
                }
            });
    };
    /* Get the ethnicities data and stored it in state varibales */
    const getEthnicity = () => {
        var url = `${enkiUrl}/ethnicity/get-active-ethnicity`;
        let ethnicities_list = [];
        let ethnicitiesArray = [];
        const requestOptions = {
            method: "GET",
        };
        
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.status == "SUCCESS") {
                    ethnicities_list = data.ethnicity;
                    let uniqueObject = {};
                    // looping based on the category and stored it in uniqueObject
                    for (let i in ethnicities_list) {
                        let objTitle = ethnicities_list[i]["category"];
                        uniqueObject[objTitle] = ethnicities_list[i];
                    }
                    // looping the uniqueObject data and pushing it to ethnicitiesArray
                    for (let i in uniqueObject) {
                        ethnicitiesArray.push(uniqueObject[i]);
                    }
                    setEthnicities(ethnicitiesArray);
                    setEthnicitiesList(ethnicities_list);
                    if (commonLayoutData?.ethnicity_category) {
                        var ethincity_data = {};
                        ethnicitiesArray.forEach((item, index) => {
                            if (item.cat_id == commonLayoutData.ethnicity_category) {
                                ethincity_data = item;
                            }
                        });
                        showEthnicities(ethincity_data, ethnicities_list);
                    }
                }
            });
    };
    const showEthnicities = (ethnicity_category, ethnicities_list) => {
        setOtherEnthnicityFlag(false);
        var selected_category = [];
        let ethnic_name = "";
        let other_ethnic_name = "";
        ethnic_name = ethnicity_category.category;
        var temp_str = ethnic_name?.toUpperCase();
        ethnic_name = ethnic_name?.substr(0, temp_str.indexOf("ETHNIC"));
        if (ethnic_name == "") {
            ethnic_name = ethnicity_category.category;
        }
        // Spliting the ethnic name for other ethnic name text box label
        other_ethnic_name = ethnic_name?.substr(0, ethnic_name.indexOf(" "));
        other_ethnic_name = other_ethnic_name?.replace(",", "");
        // If no space found after a string reinsert the string
        if (other_ethnic_name == "") {
            other_ethnic_name = ethnic_name;
        }
        var ethnicitiesListData = [];
        if (ethnicities_list) {
            ethnicitiesListData = ethnicities_list;
        } else {
            ethnicitiesListData = [...ethnicitiesList];
        }
        ethnicitiesListData.forEach((value, key) => {
            if (value.cat_id == ethnicity_category.cat_id) {
                var temp = value.name?.toUpperCase();
                var temp_1 = value.category?.toUpperCase();

                // Condition to add static content only for African ethnicity
                if (temp_1.includes("AFRICAN")) {
                    if (temp.includes("AFRICAN")) {
                        setAfrican(value.id);
                    }
                }
                // Condition to add static content only for other ethnicity
                if (temp.includes("OTHER")) {
                    setOtherField(value.id);
                }
                if (commonLayoutData.ethnicity == value.id) {
                    if (temp.includes("OTHER")) {
                        setOtherField(value.id);
                        setOtherEthnicName(other_ethnic_name);
                        setOtherEnthnicityFlag(true);
                        setOtherEthinicFullName(value.name);
                    }
                }
                selected_category.push(value);
                setSelectedCategory(selected_category);
            }
        });

        setOtherEthnicName(other_ethnic_name);
        setEthnicName(ethnic_name);
        setEthnicity(ethnicity_category);
    };
    const checkOtherEthnicity = ethnicity_id => {
        setOtherEnthnicityFlag(false);
        // other_ethnicity_text = "";
        selectedCategory.forEach((value, key) => {
            if (value.id == ethnicity_id) {
                var temp = value.name.toUpperCase();
                if (temp.includes("OTHER")) {
                    setOtherEnthnicityFlag(true);
                    setOtherEthinicFullName(value.name);
                }
            }
        });
    };
    
    /* Auto Complete for parent occupation */
    const filterOccupation = function (val) {
        var url = `${enkiUrl}/ns-sec-job-title/autocomplete?term=` + val;
        const requestOptions = {
            method: "GET",
        };
        fetch(url, requestOptions)
            .then(response => response.json())
            .then(data => {
                let results = data.filter(postCode => {
                    return postCode.value.toLowerCase().startsWith(val.toLowerCase());
                });
                setOccupationSuggestions(results);
            });
    };
    const nationality = {
        BRITISH: "British",
        ENGLISH: "English",
        NORTHERN_IRISH: "Northern Irish",
        SCOTTISH: "Scottish",
        WELSH: "Welsh",
        OTHER: "Other",
        PREFER_NOT_TO_DISCLOSE: "Prefer not to disclose",
    };
    /* sexualOrientationList static data */
    const sexualOrientationList = {
        BISEXUAL: "Bisexual",
        GAY_LESBIAN: "Gay or Lesbian",
        HETEROSEXUAL_STRAIGHT: "Heterosexual or straight",
        PREFER_NOT_TO_DISCLOSE: "Prefer not to disclose",
        PREFER_TO_SELF_DESCRIBE: "Prefer to self-describe",
    };
    /* religionOrBeliefList static data */
    const religionOrBeliefList = {
        NO_RELIGION: "No religion",
        BUDDHIST: "Buddhist",
        CHRISTIAN: "Christian",
        HINDU: "Hindu",
        JEWISH: "Jewish",
        MUSLIM: "Muslim",
        SIKH: "Sikh",
        OTHER_RELIGION: "Any other religion",
        PREFER_NOT_TO_SAY: "Prefer not to say",
    };
    /* maritalStatusList static data */
    const maritalStatusList = {
        DIVORCED: "Divorced",
        MARRIED: "Married",
        SINGLE: "Single",
        WIDOWED: "Widowed",
        PREFER_NOT_TO_SAY: "Prefer not to say",
    };
    /* disabilities static data */
    const disabilities = {
        YES: "Yes",
        NO: "No",
        PREFER_NOT_TO_DISCLOSE: "Prefer not to disclose",
    };
    const fetchReferences = async () => {
        const response = await fetch(`${enkiUrl}/references/get-active-references`);
        const responseJson = await response.json();
        let data = responseJson.references;
        data.splice(0, 0, { id: null, name: "Select from drop-down menu" });
        setReferences(data);
    };
    const saveDataObject = (data) => {
        setTimeout(() => {
            if (data.ethnicity_category) {
                setEthnicityId(data?.ethnicity_category)
            }
        }, 1000);

        // setting disability value
        if (data.disability) {
            if (data?.disability === "YES") {
                setDisability(true);
            } else {
                setDisability(false);
            }
        }

        if (!other_occupation_flag) {
            data.other_occupation_text = null
        }
        if (data.no_occupation === true) {
            data.parent_carer_one_text = null
            data.parent_carer_one_occupation_group = null
            data.other_occupation_text = null
            setCareerOccupation("")
        }
    }
    const saveForLater = (pageId, progressPercent) => {
        let data = { ...data, ...commonLayoutData };
        //save for later move to last updated page
        if (commonLayoutData.page_id > pageId) {
            data.page_id = commonLayoutData.page_id;
            data.progress_percentage = commonLayoutData.progress_percentage;
        }
        else {
            data.page_id = pageId;
            data.progress_percentage = progressPercent;
        }
        saveDataObject(data)
        partialDataStore({ data });
    };
    const submitData = data => {
        var disadvantage = commonLayoutData?.associate_disadvantages;
        var newData = disadvantage.map((item) => {
            return JSON.parse(item)
        });
        data["associate_disadvantages"] = [...newData];
        data = { ...commonLayoutData, ...data }
        if(!data.no_occupation){
            if (data.parent_carer_one_text_id != 'undefined' && data.parent_carer_one_text_id == ""  || data.parent_carer_one_text_id == null) {
                setError('Please select the occupation of your main household earner');
                return;
            }
        }
        saveDataObject(data)
        submitForm(data);
        setError("");
    };

    return (
        <StyledWrapper className='apply-now'>
            <h2>Equality, Diversity and Inclusion</h2>
            <span className="mr-2"
                dangerouslySetInnerHTML={{
                    __html:
                        `upReach was founded on the principles of fairness and equal opportunity, so it's important to us that we 
                        monitor the diversity and background of the students we support. This information will be used in line with 
                        upReach's`
                }}
            ></span>
            <a
                href="https://upreach.org.uk/upreach-associate-user-agreement/"
                target="_blank"
                className="checkbox-link d-inline" >
                Associate User Agreement and Data Protection Policy.
            </a>
            <Form onSubmit={handleSubmit(submitData)}>
                <Form.Label>
                    Gender
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    name="gender"
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                    value={commonLayoutData?.gender}>
                    <option value="" selected>
                        Select gender
                    </option>
                    <option value={"MALE"}>Male</option>
                    <option value={"FEMALE"}>Female</option>
                    <option value={"PREFER_TO_SELF_DESCRIBE"}>
                        Prefer to self-describe
                    </option>
                    <option value={"PREFER_NOT_TO_DISCLOSE"}>
                        Prefer not to disclose
                    </option>
                </Form.Control>
                {errors.gender && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select your gender
                    </p>
                )}
                <Form.Label>
                    How would you describe your national identity?
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    name="nationality"
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}>
                    <option value="" selected> Select national identity </option>
                    {Object.entries(nationality).map(([key, value], index) => (
                        <option key={index} value={key} selected={
                            key == commonLayoutData?.nationality
                        }>
                            {value}
                        </option>
                    ))}
                </Form.Control>
                {errors.nationality && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select your national identity
                    </p>
                )}
                <Form.Label>
                    What is your sexual orientation?
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    name="sexual_orientation"
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}>
                    <option value="" selected>
                        Select sexual orientation
                    </option>
                    {Object.entries(sexualOrientationList).map(([key, value], index) => (
                        <option key={index} value={key} selected={
                            key == commonLayoutData?.sexual_orientation}>
                            {value}
                        </option>
                    ))}
                </Form.Control>
                {errors.sexual_orientation && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select your sexual orientation
                    </p>
                )}
                <Form.Label>
                    Religion or belief
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    name="religion_or_belief"
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                >
                    <option value="" selected>
                        Select religion or belief
                    </option>
                    {Object.entries(religionOrBeliefList).map(([key, value], index) => (
                        <option key={index} value={key} selected={
                            key == commonLayoutData?.religion_or_belief}>
                            {value}
                        </option>
                    ))}
                </Form.Control>
                {errors.religion_or_belief && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select religion or belief
                    </p>
                )}
                <Form.Label>
                    Marital status
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    name="marital_status"
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                >
                    <option value="" selected>
                        Select marital status
                    </option>
                    {Object.entries(maritalStatusList).map(([key, value], index) => (
                        <option key={index} value={key} selected={
                            key == commonLayoutData?.marital_status}>
                            {value}
                        </option>
                    ))}
                </Form.Control>
                {errors.marital_status && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select marital status
                    </p>
                )}
                <Form.Label>Do you consider yourself to have a disability as defined by the Equality Act 2010?
                    <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                    name="disability"
                    onClick={handleChange}>
                    <option value="" selected>
                        Select disability
                    </option>
                    {Object.entries(disabilities).map(([key, value], index) => (
                        <option key={index} value={key} selected={
                            key == commonLayoutData?.disability}>
                            {value}
                        </option>
                    ))}
                </Form.Control>
                {errors.disability && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please fill in the disability field
                    </p>
                )}
                {disabilityYes && (
                    <Form.Control
                        className="mt-4"
                        type="text"
                        ref={register({ required: true })}
                        onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                        name="disability_text"
                        defaultValue={commonLayoutData.disability_text}
                        placeholder="Could you please share details of your disability? e.g. dyslexia, hearing impairment, mental health problem"
                    ></Form.Control>
                )}
                {errors.disability_text && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please share details of your disability
                    </p>
                )}
                <Form.Label>
                    Ethnicity <span className="required-text"> *</span>
                </Form.Label>
                <Form.Control
                    as="select"
                    ref={register({ required: true })}
                    id="ethnicity_category"
                    name="ethnicity_category"
                    onChange={handleChange1}
                >
                    <option value="" selected>
                        Select ethnicity
                    </option>
                    {Object.values(ethnicities).map((x, value) => (
                        <option key={value} value={x.cat_id} selected={
                            x.cat_id === Number(commonLayoutData?.ethnicity_category)}>
                            {x.category} - {x.cat_description}
                        </option>
                    ))}
                </Form.Control>
                {errors.ethnicity_category && (
                    <p className="mb-0 text-danger font-weight-bold">
                        Please select your ethnicity group
                    </p>
                )}
                {ethnicName != undefined &&
                    ethnicName != "" &&
                    ethnicName != "Prefer not to disclose" && (
                        <Form.Label>
                            Which one best describes your {ethnicName} ethnic group or
                            background?
                        </Form.Label>
                    )}
                {ethnicName != undefined &&
                    ethnicName != "" &&
                    ethnicName != "Prefer not to disclose" && (
                        <Form.Group>
                            <Form.Control
                                as="select"
                                id="ethnicity"
                                ref={register({ required: true })}
                                name="ethnicity"
                                onChange={handleChange1}
                                defaultValue={commonLayoutData?.ethnicity}
                            >
                                <option value="" selected>
                                    Select Broad ethnicity
                                </option>
                                {selectedCategory.map(
                                    (x, index) =>
                                        x.cat_id == ethnicityId && (
                                            <option value={x.id} key={index}
                                                selected={Number(commonLayoutData?.ethnicity) == x.id}
                                            >{x.name}</option>
                                        )
                                )}
                            </Form.Control>
                            {errors.ethnicity && (
                                <p className="mb-0 text-danger font-weight-bold">
                                    Please select your Broad ethnicity
                                </p>
                            )}
                        </Form.Group>
                    )}
                {otherEnthnicityFlag && (
                    <Form.Group>
                        <Form.Label>
                            You selected "{otherEthinicFullName}". How would you describe your{" "}
                            {otherEthnicName} ethnic group or background?
                        </Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter your own answer"
                            ref={otherEnthnicityFlag ? register({ required: true }) : null}
                            onChange={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }) }}
                            name="other_ethnicity_text"
                            defaultValue={commonLayoutData !== null ? commonLayoutData.other_ethnicity_text : ""}
                        ></Form.Control>
                        {errors.other_ethnicity_text && (
                            <p className="mb-0 text-danger font-weight-bold">
                                Please enter other ethnicity
                            </p>
                        )}
                    </Form.Group>
                )}
                <Form.Label>Please tick any statements that apply to you</Form.Label>
                {disadvantages && disadvantages.length > 0 &&
                    disadvantages.map(disadvantage => (
                        <Form.Check
                            type="checkbox"
                            ref={register({ required: false })}
                            onClick={e => {
                                setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                            }}
                            checked={commonLayoutData.associate_disadvantages && (commonLayoutData.associate_disadvantages.includes(JSON.stringify(disadvantage.id)) == true) ? true : false}
                            value={JSON.stringify(disadvantage.id)}
                            name="associate_disadvantages"
                            id={`disadvantages${disadvantage.id}`}
                            htmlFor={`disadvantages${disadvantage.id}`}
                            label={disadvantage.name}
                        />
                    ))}
                {!noOccupation && !occupationData && (
                    <>
                        <Form.Group>
                            <Form.Label>
                                What was the occupation of your main household earner when you were
                                about aged 14? Please select 'other' if you cannot find the relevant
                                occupation.<span className="required-text"> *</span>
                            </Form.Label>
                            <Form.Control
                                autoComplete="off"
                                type="text"
                                id="parent_carer_one_text"
                                ref={register({ required: true })}
                                placeholder="Enter main household earner occupation"
                                name="parent_carer_one_text"
                                defaultValue={commonLayoutData?.parent_carer_one_text}
                                onKeyPress={e => e.which == 13 && e.preventDefault()}
                                onChange={e => {
                                    e.target.value.length > 2 ? filterOccupation(e.target.value) : setOccupationSuggestions(null);
                                    setCommonLayoutData({ ...commonLayoutData, ...getValues() })
                                    setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                                }}
                            ></Form.Control>
                            {occupationSuggestions != null && occupationSuggestions != "" && (
                                <div className="autocomplete_sugestions shadow p-3">
                                    {occupationSuggestions.map(x => (
                                        <div>
                                            <a
                                                href=""
                                                className="pb-1 text-reset text-decoration-none"
                                                value={x.id}
                                                onClick={e => {
                                                    e.preventDefault();
                                                    document.querySelector("#parent_carer_one_text").value = x.value;
                                                    commonLayoutData["parent_carer_one_text_id"] = x.id;
                                                    setOtherOccupationFlag(x.value == "Other" ? true : false)
                                                    setOccupationSuggestions(null);
                                                }}>
                                                {x.value}
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            )}
                            {errors.parent_carer_one_text && (
                                <p className="mb-0 text-danger font-weight-bold">
                                    Please select the occupation of your main household earner
                                </p>
                            )}
                        </Form.Group>
                        {other_occupation_flag && (
                            <Form.Group>
                                <Form.Control
                                    className="mt-4"
                                    type="text"
                                    id="other_occupation_text"
                                    ref={register({ required: true })}
                                    onChange={e => {
                                        setCommonLayoutData({ ...commonLayoutData, ...getValues() })
                                    }}
                                    placeholder="Enter your occupation of your main household earner"
                                    defaultValue={commonLayoutData?.other_occupation_text}
                                    name="other_occupation_text" >
                                </Form.Control>
                                {errors.other_occupation_text && (
                                    <p className="mb-0 text-danger font-weight-bold">
                                        Please enter the occupation of your main household earner
                                    </p>
                                )}
                            </Form.Group>
                        )}
                    </>
                )}

                {!other_occupation_flag && (
                    <Form.Check
                        className="mt-4"
                        name="no_occupation"
                        id="no_occupation"
                        htmlFor="no_occupation"
                        type="checkbox"
                        ref={register({ required: false })}
                        label="I don't know/Not applicable"
                        onClick={e => {
                            setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                            setNoParentOccupation(!noOccupation);
                        }}
                        checked={commonLayoutData?.no_occupation}
                        onChange={
                            () => {
                                setNoParentOccupation(!noOccupation)
                            }
                        } />
                )}

                {!occupationData && !noOccupation && (
                    <div className="mt-4">
                        <strong>
                            Which of the following occupation groups best describes that of your main household earner when you were about 14 years old?
                        </strong>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_1"
                                htmlFor="PROFESSION_1"
                                type="radio"
                                value="PROFESSION_1"
                                label={
                                    <><strong className="fw-700 mr-2">
                                        Modern professional &amp; traditional professional occupations
                                    </strong>
                                        such as: teacher, nurse, physiotherapist, social worker,
                                        musician, police officer (sergeant or above), software designer,
                                        accountant, solicitor, medical practitioner, scientist, civil /
                                        mechanical engineer.</>
                                }
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_1" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                name="parent_carer_one_occupation_group"
                                style={{ display: "inline-block" }}
                            />
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_2"
                                htmlFor="PROFESSION_2"
                                type="radio"
                                label={
                                    <><strong className="fw-700 mr-2">Senior, middle or junior managers or administrators</strong>
                                        such as: finance manager, chief executive, large business owner,
                                        office manager, retail manager, bank manager, restaurant
                                        manager, warehouse manager.</>
                                }
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_2" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_2"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_3"
                                htmlFor="PROFESSION_3"
                                type="radio"
                                label={
                                    <><strong className="fw-700 mr-2">Clerical and intermediate occupations</strong>
                                        such as: secretary, personal assistant, call centre agent,
                                        clerical worker, nursery nurse.</>
                                }
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_3" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_3"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_4"
                                htmlFor="PROFESSION_4"
                                type="radio"
                                label={
                                    <>
                                        <strong className="fw-700 mr-2">Technical and craft occupations</strong>
                                        such as: motor mechanic, plumber, printer, electrician,
                                        gardener, train driver.
                                    </>
                                }
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_4" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_4"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_5"
                                htmlFor="PROFESSION_5"
                                type="radio"
                                label={<>
                                    <strong className="fw-700 mr-2">
                                        Routine, semi-routine manual and service occupations
                                    </strong>
                                    such as: postal worker, machine operative, security guard,
                                    caretaker, farm worker, catering assistant, sales assistant, HGV
                                    driver, cleaner, porter, packer, labourer, waiter/waitress, bar
                                    staff.
                                </>}
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_5" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_5"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_6"
                                htmlFor="PROFESSION_6"
                                type="radio"
                                label={<><strong className="fw-700 mr-2"> Long-term unemployed</strong>
                                    (claimed Jobseeker’s Allowance or earlier unemployment benefit
                                    for more than a year).</>}
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_6" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_6"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                            <span>
                            </span>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_7"
                                htmlFor="PROFESSION_7"
                                type="radio"
                                label={<><strong className="fw-700 mr-2">
                                    Small business owners who employed less than 25 people
                                </strong>
                                    such as: corner shop owners, small plumbing companies, retail
                                    shop owner, single restaurant or cafe owner, taxi owner, garage
                                    owner.</>}
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_7" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_7"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Check
                                id="PROFESSION_8"
                                htmlFor="PROFESSION_8"
                                type="radio"
                                label={<> <strong className="fw-700 mr-2"> Other</strong> such as: retired,
                                    this question does not apply to me, I don’t know, I prefer not
                                    to say</>}
                                checked={commonLayoutData?.parent_carer_one_occupation_group == "PROFESSION_8" ? true : false}
                                onClick={e => { setCommonLayoutData({ ...commonLayoutData, ...getValues() }); }}
                                ref={register({ required: true })}
                                value="PROFESSION_8"
                                style={{ display: "inline-block" }}
                                name="parent_carer_one_occupation_group"
                            ></Form.Check>
                        </Form.Group>
                        {errors.parent_carer_one_occupation_group && (
                            <p className="mb-0 text-danger font-weight-bold">
                                Please select the occupation group which best describes that of
                                your main householder earner
                            </p>
                        )}
                    </div>
                )}

                <Form.Group className="mt-4">
                    <Form.Check
                        ref={register({ required: true })}
                        name="form1_data_policy"
                        id="form1_data_policy"
                        htmlFor="form1_data_policy"
                        label="By ticking this box, I agree to the "
                        feedback="You must agree before submitting."
                        onChange={
                            () => {
                                setDataPolicy(!dataPolicy)
                                setCommonLayoutData({ ...commonLayoutData, ...getValues() });
                            }
                        }
                        checked={dataPolicy}
                        style={{ display: "inline-block" }}
                    />
                    <a
                        href="https://upreach.org.uk/upreach-associate-user-agreement"
                        target="_blank"
                        className="checkbox-link ml-2">
                        upReach's Associate User Agreement and Data Protection Policy.
                    </a>
                </Form.Group>
                {errors.form1_data_policy && (
                    <p className="mb-0 text-danger font-weight-bold">Required</p>
                )}

                {error !== "" && <p className="fail-text mb-0">{error}</p>}
                {err !== "" && <p className="fail-text mb-0">{err}</p>}
                <div className="row text-center">
                    <div className="col-lg-2 text-lg-left order-lg-first order-last">
                        <Button
                            onClick={(e) => {
                                e.target.disabled = true
                                setStep(4);
                                setProgressValue(60);
                            }}
                            className="submit-form mt-3 p-2"
                            type="button">
                            BACK
                        </Button>
                    </div>
                    <div className="col-lg-10 text-lg-right">
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                saveForLater(5, 80);
                            }}
                            className="mr-lg-5 mr-md-4 cancel-btn btn"
                        >
                            SAVE FOR LATER
                        </button>
                        <Button className="submit-form mt-3 p-2" type="submit">
                            SUBMIT
                        </Button>
                    </div>
                </div>
            </Form>
        </StyledWrapper>
    );
};

export default RegisterFifthForm;
