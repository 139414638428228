import React from "react";
import "./VideoLibrary.css";
import styled from "styled-components";
import thumbnail from "../images/Thumbnail1.svg";
import playthumb from "../images/playthumb.svg";
import hero from "../images/Hero.jpg";
import ReactMarkdown from "react-markdown";
import img1 from "../images/1.jpg";
import img2 from "../images/2.jpg";
import img3 from "../images/3.jpg";
import img4 from "../images/4.png";

const StyledWrapper = styled.div`
  .img-class {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: top;
    @media (max-width: 600px) {
      min-height: auto;
    }
  }

  .img-class2 {
    width: 100%;
    object-fit: cover;
    object-position: top;
    max-height: 405px;
    @media (max-width: 600px) {
      height: auto;
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .main {
    width: 100%;
    text-align: center;
  }

  .main div {
    display: inline-block;
    margin: 0 auto;
    padding: 3px;
  }

  .texts {
    font-family: Nunito Sans;
    font-size: 20px;
    font-weight: 800;
    line-height: 50px;
    letter-spacing: 0px;
    text-align: center;
    position: absolute;
    color: #ffffff;
    max-width: 500px;
    top: 32%;
    left: 165px;
    text-align: center;
    z-index: 1;
    line-height: 32px;
    @media (max-width: 600px) {
      top: 20%;
      left: 100px;
    }
  }

  .summary__title {
    font-family: Nunito Sans;
  }

  .summary__desc {
    font-family: Nunito Sans;
  }

  .centered {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .play_btn2 {
    width: 50px;
  }

  .play_btn {
    width: 40px;
  }

  .thumbnail_text {
    color: black;
    font-family: Nunito Sans;
    text-align: center;
    line-height: 24px;
    @media (max-width: 768px) {
      line-height: 18px;
    }
  }

  .overlay-text {
    z-index: 1;
    position: absolute;
    width: 90%;
    height: 100%;
  }

  .padding-block {
    padding: 0 16% !important;
    @media (max-width: 768px) {
      padding: 4% 16% !important;
    }
  }

  .markdownStyle strong {
    font-family: Nunito Sans;
    font-size: 32px;
    font-weight: 800;
    line-height: 43px;
    @media (max-width: 600px) {
      font-size: 24px;
      line-height: 18px;
    }
  }
  .markdownStyle > p {
    padding-bottom: 20px;
  }
  .markdownStyle > h1 {
    padding-top: 20px;
  }
  .content iframe {
    @media (min-width: 300px) and (max-width: 896px) {
      width: 324px;
      height: 180px;
    }
    @media (min-width: 200px) and (max-width: 280px) {
      width: 237px;
      height: 134px;
    }
    @media (min-width: 768px) and (max-width: 1180px) {
      width: 388px;
      height: 219px;
    }
  }
`;

// const Videos2 = [
//   {
//     thumbnail: `${img1}`,
//     heading: "9 students discuss their journeys to university",
//     href: "https://youtu.be/c3mWolGAouo",
//   },
//   {
//     thumbnail: `${img2}`,
//     heading: "10 top tips for tackling university applications",
//     href: "https://youtu.be/omrYMJ4kCdo",
//   },
//   {
//     thumbnail: `${img3}`,
//     heading: "9 insights into what university life is really like",
//     href: "https://youtu.be/dCBa1sF-xwc",
//   },
//   {
//     thumbnail: `${img4}`,
//     heading:
//       "6 students' insights into when you should start thinking about your career",
//     href: "https://youtu.be/4MPTtCeAAWE",
//   },
// ]

function VideoLibrary({ summary, Video2, showVideo1, showVideo2 }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="content">
      {showVideo1 && (
        <div
          className={`Videolibrary container-fluid pt-5 ${
            showVideo2 === false ? "pb-5" : ""
          }`}
        >
          <div className="row">
            <div className="col-12 padding-left-8 padding-right-8 d-flex justify-content-bewteen d-flex flex-column order-2 order-md-1">
              <ReactMarkdown
                className="markdownStyle"
                source={summary.Summary.replace(/```/g, '')}
                escapeHtml={false}
              />
            </div>
          </div>

          <div className="row padding-left-8 padding-right-8">
            {summary.VideoList.length > 0 &&
              summary.VideoList.map(item => (
                <div
                  className="col-sm-12 col-md-4  align-items-center"
                  onClick={() => window.open(item.link)}
                >
                  <div
                    className="row mx-0"
                    style={{
                      width: "100%",
                      height: "295px",
                      cursor: "pointer",
                    }}
                  >
                    {item.Thumbnail && (
                      <img
                        src={mainUrl + item.Thumbnail.url}
                        className="img-fluid img-class"
                      />
                    )}
                    <div className="d-flex pb-5 align-items-center justify-content-center overlay-text">
                      <img className="play_btn" src={playthumb} />
                    </div>
                  </div>
                  <div>
                    <h2 className="thumbnail_text pb-5 pt-3">{item.heading}</h2>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {showVideo2 && (
        <div
          className={`Videolibrary container-fluid pb-5 ${
            showVideo1 === false ? "pt-5" : ""
          }`}
        >
          <div className="row">
            <div className="col-sm-12 col-md-12 padding-left-8 padding-right-8 d-flex justify-content-bewteen d-flex flex-column order-2 order-md-1">
              <ReactMarkdown
                className="markdownStyle pb-3"
                source={summary.Summary2}
              />
            </div>
          </div>

          <div className="row padding-left-8 padding-right-8">
            {Video2.length > 0 &&
              Video2.map(item => (
                <div
                  className="col-12 col-md-6 align-items-center"
                  onClick={() => window.open(item.link)}
                >
                  <div
                    className="row mx-0"
                    style={{ width: "100%", cursor: "pointer" }}
                  >
                    {item.Image && (
                      <img
                        src={mainUrl + item.Image.url}
                        className="img-fluid img-class2"
                      />
                    )}
                    <div className="d-flex pb-5 align-items-center justify-content-center overlay-text">
                      <img className="img-fluid play_btn2" src={playthumb} />
                    </div>
                  </div>
                  <h2 className="thumbnail_text pb-5 pt-3">{item.title}</h2>
                </div>
              ))}
          </div>
        </div>
      )}
      </div>
    </StyledWrapper>
  );
}

export default VideoLibrary;
