import React from "react"
import styled from "styled-components";

const StyledWrapper = styled.div`
  .hero-img {
    width: 100%;
    height: 30vw;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px){
      height: 55vw;
    }
  }
`;

const HeroImages = ({ heroImage }) => {
  return (
    <StyledWrapper>
      {heroImage.Image !== null ? <img
        className="hero-img"
        src={heroImage}
        alt=""
      /> : <></>}
    </StyledWrapper>
  );
};

export default HeroImages;
