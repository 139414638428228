import React from "react";
import FlatButton from "./FlatButton";
import styled from "styled-components";
import ReactMarkdown from "react-markdown";
import ReactGA from "react-ga";

const StyledWrapper = styled.div`
  .home-summary-cta {
    text-decoration: none;
    color: black;
  }

  .mission {
    @media (max-width: 600px) {
      margin-top: 20px;
    }
  }

  .summary-title {
    font-weight: 800;
    font-size: 32px;
    line-height: 43px;
    font-family: ${props => props.theme["primaryFont"]} !important;
    text-transform: uppercase;
  }

  h1 {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }

  p a {
    text-decoration: underline;
    color: black;
    font-family: ${props => props.theme["primaryFont"]} !important;
    font-size: 18px;
    font-weight: 800;
  }

  p strong a {
    color: black !important;
    text-decoration: underline !important;
    font-family: Nunito Sans !important;
    font-size: 23px !important;
    font-weight: 800 !important;
    line-height: 43px !important;
    text-transform: none !important;
    @media (max-width: 600px) {
      font-size: 20px !important;
      line-height: 18px !important;
    }
  }
  .summary__link {
    color: black !important;
    text-decoration: underline !important;
    font-family: Nunito Sans !important;
    font-size: 23px !important;
    font-weight: 800 !important;
    line-height: 43px !important;
    text-transform: none !important;
    @media (max-width: 991px) {
      font-size: 20px !important;
      line-height: 18px !important;
    }
  }
  @media (max-width: 1092px) {
    .row {
      display: flex;
      flex-direction: column;
    }
    .col-lg-6 {
      max-width: 100%;
      display: flex;
      justify-content: center;
    }
  }
  .content iframe {
    @media (min-width: 300px) and (max-width: 896px) {
      width: 324px;
      height: 180px;
    }
    @media (min-width: 200px) and (max-width: 280px) {
      width: 237px;
      height: 134px;
    }
    @media (min-width: 768px) and (max-width: 1180px) {
      width: 388px;
      height: 219px;
  }
`;

const SummaryBlock = ({ summary }) => {
  const handleParent = () => {
    ReactGA.event({
      category: "Parents",
      action: "Access Your Copy Button Click",
    });
  };
  const handleTeacher = () => {
    ReactGA.event({
      category: "Teachers",
      action: "Access Your Toolkit Button Click",
    });
  };
  const handleSubscribe = () => {
    ReactGA.event({
      category: "Teachers",
      action: "Subscribe to our newsletter Button Click",
    });
  };
  const mainUrl = process.env.GATSBY_API_URL;
  const lines = summary.Summary.split('\n')

  return (
    <StyledWrapper>
       <div className="content">
      <div className="container-fluid py-5">
        <div className="row">
          <div
            className={`col-12 col-lg-6 flex-column justify-content-center padding-left-8 padding-right-8 mission order-2 order-md-1  ${
              summary.Image !== null &&
              summary.Image !== undefined &&
              (summary.Image.url !== undefined ||
                summary.Image.publicURL !== undefined)
                ? "col-lg-6"
                : "col-lg-8"
            }`}
          >
            <div>
                {lines && lines.map((line, index) => {
                  const NewLine = line.replace(/```/g, '');
                  return(
                  <div>
                  {line && <ReactMarkdown className="markdown" source={NewLine} escapeHtml={false} />}
                  </div>
                  )
                })
            }
            </div>
            {summary.id === 3 ? (
              <a
                onClick={handleParent}
                href="https://www.paperturn-view.com/uk/upreach/the-aspire-guide?pid=MjQ246596&v=1.1"
                className="summary__link"
              >
                Access your free copy of the Aspire guide
              </a>
            ) : summary.id === 2 ? (
              <div>
                <a
                  onClick={handleTeacher}
                  href="/teacher-toolkit"
                  className="summary__link"
                >
                  Access your Toolkit
                </a>
                <br />
                <a
                  onClick={handleSubscribe}
                  href="https://upreach.us5.list-manage.com/subscribe?u=3dfe181de601dbeb5cc060696&id=7574a78c19"
                  className="summary__link"
                >
                  Subscribe to our newsletter
                </a>
              </div>
            ) : (
              <></>
            )}
          </div>
          <div className="col-12 col-lg-6 order-1 order-md-1 padding-left-8 padding-right-8 pt-4">
            {summary.Image !== null &&
            summary.Image !== undefined &&
            summary.Image.publicURL !== undefined &&
            summary.Image.publicURL !== null ? (
              <img
                className="img-fluid mx-auto"
                src={mainUrl + summary.Image.url}
                alt=""
              />
            ) : (
              <></>
            )}
            {summary.Image !== null &&
            summary.Image !== undefined &&
            summary.Image.url !== undefined &&
            summary.Image.url !== null ? (
              <img
                className="img-fluid mx-auto"
                src={mainUrl + summary.Image.url}
                alt=""
              />
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      </div>
    </StyledWrapper>
  );
};

export default SummaryBlock;
