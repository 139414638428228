import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../components/Banner";
import Form from "../components/Form";
import Layout from "../components/layout";
import HeroImages from "../components/HeroImage";
import SummaryBlock from "../components/SummaryBlock";
import hero from "../images/Hero.jpg";
import styled from "styled-components";
import UpreachLogo from "../images/UpreachLogo.svg";
import BannerImage from "../components/BannerImage";
import VideoLibrary from "../components/VideoLibrary";
import Image2 from "../images/Image2.svg";
import Guide from "../components/Guide";
import summaryimage from "../images/Bitmap.svg";
import HeroAnimate from "../components/HeroAnimate";
import MobileAnimate from "../components/MobileAnimate";
import fsiAwardlogo from "../images/FSI-Award.jpg";
import charityGovernenceAward from "../images/Charity-Governance-Award.jpg";
import charityTimesAward from "../images/Charity-Times-Award.jpg";
import ContactForm from "../components/Form";
import SEO from "../components/seo";
import Register from "../components/register/Register";
import ReactGA from "react-ga";
import ReactMarkdown from "react-markdown";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Loader";
import CivicIntegration from "../components/cookies/cookies";
import GoogleConsentModeScript from "../components/cookies/GoogleConsentMode";

const StyledWrapper = styled.div`
  .about-images {
    height: 200px;
    contain: content;
    padding: 15px;
  }

  .top-div-heading {
    text-transform: uppercase;
    text-align: center;
    font-family: ${props => props.theme["primaryFont"]} !important;
    margin-bottom: 0px;
    @media (max-width: 600px) {
      line-height: 20px;
    }

    .Herobanner-heading p {
      margin-bottom: 0;
    }

    .Herobanner-heading a {
      color: black;
      text-decoration: underline;
    }
  }
`;
ReactGA.initialize("UA-100770515-15");

const body = (
  <div>
    <p>
      Are you ambitious, but unsure about what opportunities are out there? At
      upReach, we believe that all sixth-form students have the right to access
      information regarding their post-18 options.
    </p>
    <p>
      The Aspire project provides inspiration, information and advice to help
      sixth formers understand their post-18 options and career pathways.
      Thousands of young people are leaving school unsure what opportunities are
      available to them, and this year they will have had even less access to
      career support due to Covid-19.
    </p>
    <p>
      Therefore, upReach have launched the free 60-page Aspire guide aiming to
      help students understand more about careers and make more informed
      decisions about their future whilst at school. Alongside this, the Aspire
      Career Video Library has been created to provide first-person insight into
      different sectors from experts in the field.
    </p>
    <p>
      Aspire is designed to encourage sixth form students to aim high and
      achieve their full potential. Discover what people just like you have gone
      on to achieve, by reading our guide and viewing our video library!
    </p>
  </div>
);

const body2 = (
  <div>
    <p>
      In 2018, we celebrated student achievement at the inaugural Student Social
      Mobility Awards (SSMAs). The nominees and winners had overcome huge
      barriers to achieve great success academically, professionally and
      personally. We wanted to share their stories and their tips for sixth
      formers, to inspire other young people to think about their future and
      work to realise their potential.{" "}
    </p>
    <p>
      These students inspired the Aspire project. As well as the guide and
      resources for teachers, we recorded videos of the Student Social Mobility
      Award nominees discussing their experiences and answering our questions
      about university and careers. We hope that this project will inspire sixth
      formers all over the UK to aim high, stretch themselves and realise their
      potential.
    </p>
  </div>
);
const body3 = (
  <div>
    <p>
      upReach works to create the conditions for undergraduates from
      less-advantaged backgrounds to access and sustain top graduate jobs.
    </p>
    <p>
      Our main activity is delivering an intensive programme of career support,
      working in close partnership with leading employers and universities,
      which is free to students who take part. This year, upReach programmes
      will provide tailored support to over 2,000 students from across the UK,
      helping them to broaden their horizons, understand career pathways and
      develop the skills, networks and experiences needed for career success.
      Students who receive upReach’s support who leave university with a
      graduate job start on a mean salary of over £5,500 more than the average
      graduate!
    </p>
  </div>
);

const body4 = (
  <div>
    <p>
      The Aspire Career Video Library holds a range of sector specific videos
      offering an insight into the different job roles and opportunities
      available to you. We asked experts to provide guidance for those exploring
      their options to help you make more informed decisions. Watch each video
      to gain an introduction to a different sector.
    </p>

    <p>
      Thanks to the volunteers who helped make these videos happen through the
      COVID-19 pandemic.
    </p>
  </div>
);

const body5 = (
  <div>
    <p>
      Aspire is designed to encourage sixth form students to aim high, stretch
      themselves and achieve their potential.
    </p>

    <p>
      The Aspire teachers resources pack contains everything you need to deliver
      an effective and engaging session about university and careers. By
      clicking on the button above and registering, you will be sent:
    </p>

    <li>A 15 minute Assembly Presentation</li>
    <li>A shorter, 5 minute version also suitable for Daily Notices</li>
    <li>An easy-to-use Teacher Guide and Script</li>
    <li>
      A free, electronic copy of the aspire guide, an invaluable 60-page careers
      resource for your students
    </li>

    <p>
      {" "}
      The Aspire resources provide information about a range of post-18 options,
      from university to school leaver programmes and apprenticeships, as well
      as careers advice and inspiration. They also provide important role
      models: students who have come from less-advantaged backgrounds and broken
      into careers that can otherwise seem hard to reach. The presentations
      include links to our videos of high-achieving students talking about their
      experiences and their tips for sixth formers.
    </p>

    <p>
      The 15 and 5 minute versions of the assembly mean you have flexibility to
      extend or shorten the presentation according to your needs. The
      presentations can also be lengthened by playing more of the aspire videos
      above. Please feel free to pass on the teacher resources to Year 12 and 13
      tutors at your school. The aspire guide itself cannot be redistributed
      without upReach’s permission.
    </p>
  </div>
);

const body6 = (
  <div>
    <p>
      Research shows that parents are the number one source of information for
      most young people. The aspire guide will help you understand the range of
      post-school/college options out there, enabling you to help guide your son
      or daughter through an important decision.
    </p>

    <p>
      Aspire contains everything you need to encourage your child to aim high,
      stretch themselves and achieve their potential.
    </p>
  </div>
);
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <IndexPage />
    </QueryClientProvider>
  );
}
const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/aspire`);
};

const IndexPage = () => {
  const [newData, setNewData] = useState(null);
  const { data: pageData } = useQuery("aspireHomeData", fetchData);
  const [hashPath, setHashPath] = useState(null);
  
  useEffect(() => {
    const handleBeforeUnload = () => {
      // Scroll to the top of the page when refreshing or leaving the page
      window.scrollTo(0, 0);
    };

    // Attach the event listener when the component mounts
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    setHashPath(document.location.hash);
  }, [typeof document !== `undefined` ? document.location.hash : null]);

  let isScrolled = false;

  const handleScroll = () => {
  if (isScrolled) {
    window.removeEventListener('scroll', handleScroll);
  }
  };

  
  if (
    hashPath === "#videos" ||
    hashPath === "#onlineguide" ||
    hashPath === "#register" ||
    hashPath === "#teachers" ||
    hashPath === "#parents" ||
    hashPath === "#about" ||
    hashPath === "#contact"
  ) {
    if (!isScrolled) {
      isScrolled = true;
      setTimeout(() => {
        const targetElement = document.querySelector(hashPath);
        if (targetElement) {
          targetElement.scrollIntoView({ behavior: "smooth", block: "start" });
          window.scrollTo({
            top: targetElement.offsetTop - 100,
            behavior: 'smooth'
          });
        }
        // window.addEventListener('scroll', handleScroll);
      }, 2000);
    }
  }

  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
  }, [pageData]);

  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        <SEO title="Aspire" />
        {newData && newData.showHeroBanner && (
          <div className="padding-left-8 padding-right-8 py-3 home-hero-text white-background">
            <h2 className="top-div-heading Herobanner-heading">
              <ReactMarkdown source={newData.HeroBanner} />
            </h2>
          </div>
        )}
        {newData && newData.showMobileHeroAnimate && (
          <MobileAnimate
            data={newData.MobileHeroAnimate}
            text={newData.HeroText}
          />
        )}
        {newData && newData.showHeroImages && (
          <HeroAnimate data={newData.HeroImages} text={newData.HeroText} />
        )}
        <section id="onlineguide">
          {newData && newData.showAspireSummary && newData.AspireSummary && (
            <SummaryBlock summary={newData.AspireSummary} />
          )}
        </section>

        {newData && newData.Cta && <Banner Cta={newData.Cta} />}
        {newData && (
          <Guide
            Guide1={newData.Guide1}
            Guide2={newData.Guide2}
            heading1={newData.Guide1Heading}
            heading2={newData.Guide2Heading}
            showGuide1={newData.showGuide1}
            showGuide2={newData.showGuide2}
          />
        )}
        <section id="videos">
          {newData && (
            <VideoLibrary
              Video2={newData.Video2}
              summary={newData.Video}
              showVideo1={newData.showVideo1}
              showVideo2={newData.showVideo2}
            />
          )}
        </section>
        <section id="register" key={2} className="py-3 white-background">
          {newData && newData.showRegister && newData.Register && (
            <Register summary={newData.Register} />
          )}
        </section>
        <section id="teachers" className="py-3">
          {newData && newData.showTeachers && newData.Teachers && (
            <SummaryBlock summary={newData.Teachers} />
          )}
        </section>
        <section id="parents" className="white-background py-3">
          {newData && newData.showParents && newData.Parents && (
            <SummaryBlock summary={newData.Parents} />
          )}
        </section>
        <section id="about" className="py-3">
          {newData && newData.showAboutAspire && newData.AboutAspire && (
            <SummaryBlock summary={newData.AboutAspire} />
          )}
        </section>
        {newData && newData.showAboutUpreach && newData.AboutUpreach && (
          <section className="white-background py-3">
            <SummaryBlock summary={newData.AboutUpreach} />
          </section>
        )}
        {newData && newData.showBanner && newData.Banner.length > 0 && (
          <section className="py-5">
            <BannerImage data={newData.Banner} />
          </section>
        )}
        <section id="contact" className="white-background py-5">
          {newData && (
            <div className="padding-left-8 padding-right-8">
              <div className="row">
                <div className="col-sm-12 col-lg-10 col-12">
                  <h1 className="form__title">CONTACT</h1>
                  <p className="form__desc">
                    We would love to hear your feedback about Aspire.
                  </p>
                  <p className="form__desc">
                    To provide feedback or contact us about anything else,
                    including purchasing a hard copy of the Aspire guide, please
                    fill out this form:
                  </p>
                  <p className="required">
                    <span className="astrix-color">*</span>Required
                  </p>
                </div>
              </div>
              <ContactForm />
            </div>
          )}
        </section>
      </StyledWrapper>
      <CivicIntegration></CivicIntegration>
      <GoogleConsentModeScript></GoogleConsentModeScript>
    </Layout>
  );
};
