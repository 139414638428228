import React, { useState } from "react";
import "./Guide.css";
import styled from "styled-components";
import guide1 from "../images/guide1.svg";
import guide2 from "../images/guide2.svg";
import guide3 from "../images/guide3.svg";
import guide4 from "../images/guide4.svg";
import guide5 from "../images/guide5.svg";
import guide6 from "../images/guide6.svg";
import guide7 from "../images/guide7.svg";
import guide8 from "../images/guide8.svg";
import guide9 from "../images/guide9.svg";
import guide10 from "../images/guide10.svg";
import guide11 from "../images/guide11.svg";
import guide12 from "../images/guide12.svg";
import guide13 from "../images/guide13.svg";
import guide14 from "../images/guide14.svg";
import ApprenticeshipWhite from "../images/icons/Apprenticeships.svg";
import BudgetingWhite from "../images/icons/BudgetingTips.svg";
import CareerWhite from "../images/icons/CareerWhite.svg";
import DegreeWhite from "../images/icons/DegreeWhite.svg";
import EductionWhite from "../images/icons/EducationWhite.svg";
import GapWhite from "../images/icons/gap.svg";
import HelpWhite from "../images/icons/Help.svg";
import JobWhite from "../images/icons/jobWhite.svg";
import PoundWhite from "../images/icons/Pound.svg";
import PrepareWhite from "../images/icons/Preparewhite.svg";
import ProgrammesWhite from "../images/icons/ProgrammesWhite.svg";
import QualificationsWhite from "../images/icons/QualificationsWhite.svg";
import RightUniversity from "../images/icons/RightUniversity.svg";
import eduWhite from "../images/icons/Education.svg";
import ReactMarkdown from "react-markdown";

// const Guide1 = [
//   {
//     id: 5,
//     logo: `${guide5}`,
//     text: "University",
//     page: "Page 11",
//     hoverLogo: `${EductionWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=11&v=1.1",
//   },
//   {
//     id: 4,
//     logo: `${guide4}`,
//     text: "Degree Apprenticeships",
//     page: "Page 9",
//     hoverLogo: `${DegreeWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=9&v=1.1",
//   },
//   {
//     id: 6,
//     logo: `${guide6}`,
//     text: "Gap Years",
//     page: "Page 31",
//     hoverLogo: `${GapWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=29&v=7.1",
//   },
//   {
//     id: 1,
//     logo: `${guide1}`,
//     text: "Further Education Colleges",
//     page: "Page 6",
//     hoverLogo: `${eduWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=7&v=1.1",
//   },
//   {
//     id: 2,
//     logo: `${guide2}`,
//     text: "School Leaver Programmes",
//     page: "Page 7",
//     hoverLogo: `${ProgrammesWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=7&v=1.1",
//   },
//   {
//     id: 3,
//     logo: `${guide3}`,
//     text: "Apprenticeships",
//     page: "Page 8",
//     hoverLogo: `${ApprenticeshipWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=9&v=1.1",
//   },
//   ,
//   ,
//   ,
// ]

// const Guide2 = [
//   {
//     id: 7,
//     logo: `${guide7}`,
//     text: "Different qualifications and your earning potential",
//     page: "Page 13",
//     hoverLogo: `${QualificationsWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=13&v=1.1",
//   },
//   {
//     id: 8,
//     logo: `${guide8}`,
//     text: "How to choose the right university for you",
//     page: "Page 14",
//     hoverLogo: `${RightUniversity}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=15&v=1.1",
//   },
//   {
//     id: 13,
//     logo: `${guide13}`,
//     text: "Different career options to consider",
//     page: "Page 34",
//     hoverLogo: `${CareerWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=33&v=7.1",
//   },
//   {
//     id: 12,
//     logo: `${guide12}`,
//     text: "How to prepare for careers success early",
//     page: "Page 33",
//     hoverLogo: `${PrepareWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=31&v=7.1",
//   },
//   {
//     id: 14,
//     logo: `${guide14}`,
//     text: "Job applications, CV and interview guidance",
//     page: "Page 52",
//     hoverLogo: `${JobWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=53&v=1.1",
//   },
//   {
//     id: 9,
//     logo: `${guide9}`,
//     text: "Understanding the Student Finance system",
//     page: "Page 19",
//     hoverLogo: `${PoundWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=19&v=1.1",
//   },
//   {
//     id: 10,
//     logo: `${guide10}`,
//     text: "Budgeting tips",
//     page: "Page 22",
//     hoverLogo: `${BudgetingWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=21&v=6.1",
//   },
//   {
//     id: 11,
//     logo: `${guide11}`,
//     text: "Accessing wellbeing support whilst at university",
//     page: "Page 30",
//     hoverLogo: `${HelpWhite}`,
//     link:
//       "https://www.paperturn-view.com/uk/upreach/new_aspire-guide-2020?pid=MTE111704&p=29&v=7.1",
//   },
// ]

const StyledWrapper = styled.div`
  .back {
    background: #e5e5e5;
    text-align: center;
    width: 100%;
    padding-top: 42px;
    margin-top: 36px;
  }

  .back:hover {
    background: #5db9db;
    cursor: pointer;
    color: white;
  }

  .Guide__subtext > h2 {
    font-family: Nunito Sans;
    line-height: 32px;
  }

  .guide_main {
    font-family: Nunito Sans;
  }
  .Guide__subtext2 {
    padding-top: 44px;
  }
  .Guide__subtext2 > h2 {
    font-family: Nunito Sans;
    line-height: 32px;
  }

  .logo-cls {
    width: 60px;
    height: 60px;
    align-self: center;
  }

  .second-div {
    height: 265px;
    margin-top: 20px;
  }

  .item-desc {
    font-size: 17px;
    font-weight: 800;
    line-height: 24px;
    height: 48px;
  }

  .item-page {
    font-size: 17px;
    font-size: 400;
  }
`;

function Guide({ Guide1, Guide2, heading1, heading2, showGuide1, showGuide2 }) {
  const [hover, setHover] = useState(null);
  const [hoverItem, setHoverItem] = useState(null);
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      {showGuide1 && (
        <div
          className={`white-background container-fluid pt-5 ${
            showGuide2 === false ? "pb-5" : ""
          }`}
        >
          <div className="row">
            <div className="col-12 flex-column padding-left-8 padding-right-8">
              <ReactMarkdown className="Guide__subtext">
                {heading1}
              </ReactMarkdown>
            </div>
          </div>
          <div
            className="row padding-left-8 padding-right-8 py-0"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {Guide1.length > 0 &&
              Guide1.map(item => (
                <div
                  className="col-lg-4 col-md-6 col-12"
                  onClick={() => window.open(item.link)}
                >
                  <div
                    className="back d-flex px-3 justify-content-around flex-column"
                    onMouseOver={() => setHover(item.GuideID)}
                    onMouseLeave={() => setHover(null)}
                  >
                    {item.logo && item.hoverLogo && (
                      <img
                        src={
                          hover !== item.GuideID
                            ? mainUrl + item.logo.url
                            : mainUrl + item.hoverLogo.url
                        }
                        className="img-fluid mb-3 logo-cls"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    )}

                    <p className="item-desc">{item.title}</p>
                    {/* <p className="item-page">{item.page}</p> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}

      {showGuide2 && (
        <div
          className={`white-background container-fluid pb-5 ${
            showGuide1 === false ? "pt-5" : ""
          }`}
        >
          <div className="row">
            <div className="col-12 padding-left-8 padding-right-8 ml-2">
              <ReactMarkdown className="Guide__subtext2">
                {heading2}
              </ReactMarkdown>
            </div>
          </div>
          <div
            className="row padding-left-8 padding-right-8"
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
          >
            {Guide2.length > 0 &&
              Guide2.map(item => (
                <div
                  className="col-lg-3 col-md-4 col-12"
                  onClick={() => window.open(item.link)}
                >
                  <div
                    className="back d-flex px-3 pt-5 pb-3 justify-content-around flex-column second-div"
                    onMouseOver={() => setHoverItem(item.GuideID)}
                    onMouseLeave={() => setHoverItem(null)}
                  >
                    {item.logo && item.hoverLogo && (
                      <img
                        src={
                          hoverItem !== item.GuideID
                            ? mainUrl + item.logo.url
                            : mainUrl + item.hoverLogo.url
                        }
                        className="img-fluid mb-0 logo-cls"
                        alt=""
                        style={{ cursor: "pointer" }}
                      />
                    )}

                    <p className="item-desc">{item.title}</p>
                    {/* <p className="item-page">{item.page}</p> */}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </StyledWrapper>
  );
}

export default Guide;
