import React, { useEffect } from 'react';

const GoogleConsentModeScript = () => {
  useEffect(() => {
    // Define gtag() function
    if (typeof window !== 'undefined') {

    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }

    // Set default consent for Google Consent Mode
    gtag('consent', 'default', {
      'ad_storage': 'denied',
      'ad_user_data': 'denied',
      'ad_personalization': 'denied',
      'analytics_storage': 'denied'
    });

    // Load gtag.js asynchronously
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-YSME7QSYQZ';
    
    document.head.appendChild(script);

    // Clean up
    return () => {
      document.head.removeChild(script);
    };
  }}, []);
//   window.gtag = window.gtag || function () { (window.dataLayer = window.dataLayer || []).push(arguments); };
  return null; // This component doesn't render anything
};

export default GoogleConsentModeScript;
