import React from "react";
import Img1 from "../images/1.svg";
import Img2 from "../images/2.svg";
import Img3 from "../images/3.svg";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .img-class {
    width: 100%;
    max-height: 435px;
    object-fit: cover;
    margin-bottom: 0;
    @media (max-width: 600px) {
      height: 300px;
    }
  }
`;

const Images = [
  {
    img: `${Img1}`,
  },
  {
    img: `${Img2}`,
  },
  {
    img: `${Img3}`,
  },
];

function BannerImage({ data }) {
  const mainUrl = process.env.GATSBY_API_URL;
  return (
    <StyledWrapper>
      <div className="container-fluid padding-left-8 padding-right-8">
        <div className="row">
          {data.length > 0 &&
            data.map(item => (
              <div className="col-lg-4 d-flex mb-2">
                {item.Image && (
                  <img
                    src={mainUrl + item.Image.url}
                    className="img-fluid img-class"
                    alt=""
                  />
                )}
              </div>
            ))}
        </div>
      </div>
    </StyledWrapper>
  );
}

export default BannerImage;
